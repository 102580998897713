import { Block, BlockType, Image, TBanner, TTag, Video } from '@app/models';
import { Document } from 'src/api/documents/model';
import isString from 'lodash/isString';
import { TagsDto } from '../tags-api';
import { EventBlockDto, MainPageDto } from '../models';

// TODO rename to 'toImage'
export const convertImage = <T extends { url: string; sortOrder?: number } | undefined>(
  image: T,
): Image | (undefined extends T ? undefined : never) => {
  if (image === undefined) {
    return undefined as undefined extends T ? undefined : never;
  }
  return {
    url: image?.url
      ? `${process.env.REACT_APP_IMAGES_URL || 'https://admin.tzvet.ru/images/'}${image?.url}`
      : undefined,
    sortOrder: image?.sortOrder,
  };
};

export const toVideo = <T extends { url: string; sortOrder?: number } | undefined>(
  video: T,
): Video | (undefined extends T ? undefined : never) => {
  if (video === undefined) {
    return undefined as undefined extends T ? undefined : never;
  }
  return {
    url: video?.url ? `${process.env.REACT_APP_VIDEO_URL || 'https://admin.tzvet.ru/video/'}${video?.url}` : undefined,
    sortOrder: video?.sortOrder,
  };
};

export const toPdf = (element: Document): Document & { url: string } => {
  return {
    url: element?.key ? `${process.env.REACT_APP_PDF_URL}${element.key}` : '',
    ...element,
  };
};

export const toTag = (tag: TagsDto): TTag => {
  return {
    ...tag,
    id: tag._id,
  };
};

export const toBanner = ({
  sortOrder,
  buttonLink,
  buttonText,
  showButton,
  mediaLink,
  blockType,
  fontColor,
  text,
  textVisible
}: MainPageDto): TBanner => {
  return {
    title: text,
    fontColor,
    sortOrder,
    button:
      showButton === 'active'
        ? {
            text: buttonText,
            link: buttonLink,
          }
        : undefined,
    showButton,
    textVisible,
    banner: blockType === 'banner' && !isString(mediaLink) ? convertImage(mediaLink) : undefined,
    video: blockType === 'video' && !isString(mediaLink) ? toVideo(mediaLink) : undefined,
  };
};

export const toBlock = ({ type, text, images }: EventBlockDto): Block => {
  if (type === BlockType.TITLE || type === BlockType.TEXT) {
    return {
      type: type as BlockType.TEXT | BlockType.TITLE,
      text,
    };
  }
  if (type === BlockType.BANNER || type === BlockType.PHOTO || type === BlockType.GALLERY) {
    return {
      type: type as BlockType.BANNER | BlockType.PHOTO | BlockType.GALLERY,
      images: images.map(convertImage),
    };
  }
  console.warn('Unsupported block type', type);
  return { type, images, text };
};
