import { useEffect, useMemo, FC, useRef, useState, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import { useExternalControllerSubscribeEmailMutation } from '@app/api/external-api';
import { ReactComponent as LogoIcon } from '@app/assets/icons/logo.svg';
import { ReactComponent as MetasharksLogo } from '@app/assets/icons/metasharks.svg';
import { NavBar } from '@app/components';
import { SiteMap } from '@app/constants';
import { NAVBAR_DATA } from '@app/data/navbar.data';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import { localizeDayOfWeek } from '@app/i18n';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { subscribeEmailShemas, validateSchema } from '@app/formValidation';

import './footer.scss';
import 'react-tooltip/dist/react-tooltip.css';
import '@app/index.scss';

import { documentsTaggedApi } from '@app/api/tagged/documentsApi';
import { footerTaggedApi } from '@app/api/tagged/footer-api';
import { toPdf } from '@app/api/mappers/media.mappers';
import SubscribeEmail from './components/subscribeEmail';

export interface ISocialLinksFooterProp {
  value: {
    telegram: string;
    vkontakte: string;
    youtube: string;
  };
}

const SocialLink = ({ value }: ISocialLinksFooterProp): JSX.Element => {
  const { tRoot } = useEnhancedTranslation({ keyPrefix: 'footer' });
  return (
    <address className="footer__social-links">
      {[
        { name: 'contacts.socialMedia.telegram', link: value.telegram },
        { name: 'contacts.socialMedia.vkontakte', link: value.vkontakte },
        { name: 'contacts.socialMedia.youtube', link: value.youtube },
      ].map(({ name, link }) => (
        <a
          key={name}
          href={tRoot(link) || ''}
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social-link"
        >
          {tRoot(name)}
        </a>
      ))}
    </address>
  );
};

export const Footer: FC = () => {
  const { useLazyDocumentsControllerGetQuery } = documentsTaggedApi;
  const { useLazyFooterControllerFirstQuery } = footerTaggedApi;

  const [getDocuments, { data: documents }] = useLazyDocumentsControllerGetQuery();
  const [getFooter, { data: footer }] = useLazyFooterControllerFirstQuery();

  const { isMobile } = useMedia();
  const { t, tRoot } = useEnhancedTranslation({ keyPrefix: 'footer' });
  const [email, setEmail] = useState<string>('');
  const [emailLabel, setEmailLabel] = useState<string>('yourEmailLabel');
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [postEmail] = useExternalControllerSubscribeEmailMutation();

  const navbarData = NAVBAR_DATA;

  // const loyalityProgramTerms = useMemo(() => {
  //   const doc = documents?.find((el) => el.type === 'loyalty_program_terms');
  //   return doc ? toPdf(doc).url : '';
  // }, [documents]);

  // const privacyPolicy = useMemo(() => {
  //   const doc = documents?.find((el) => el.type === 'privacy_policy');
  //   return doc ? toPdf(doc).url : '';
  // }, [documents]);

  const loyalytyProgramDiscount = useMemo(() => {
    const doc = documents?.find((el) => el.type === 'loyalyty_program_discount');
    return doc ? toPdf(doc).url : '';
  }, [documents]);

  const infoLinksNodes = useMemo(
    () =>
      [
        { name: 'info.privacyPolicy.title', link: SiteMap.PRIVACY_POLICY },
        { name: 'info.faq.title', link: SiteMap.FAQ },
        { name: 'info.loyalityTerms.title', link: SiteMap.INFO_PROGRAM_TERMS },
        { name: 'footer.loyalityProgram', link: loyalytyProgramDiscount, args: { target: '_blank' } },
      ].map(({ name, link, args }) => (
        <a key={name} href={link} {...args} className="footer__info-link">
          {tRoot(name)}
        </a>
      )),
    [loyalytyProgramDiscount],
  );

  const submitEmail = async (): Promise<void> => {
    if (!email) return;
    const resultValidation = await validateSchema(subscribeEmailShemas, { email });
    if (resultValidation.valid) {
      const resp = await postEmail({ emailDto: resultValidation.data });
      setEmail('');
      emailInputRef.current && (emailInputRef.current.value = '');
      setEmailLabel('error' in resp ? 'yourEmailError' : 'yourEmailSubmitted');
    } else {
      setEmailLabel(resultValidation.errors);
    }
  };

  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      setEmail(value);
      setEmailLabel(e.target.value ? 'yourEmailConfirmation' : 'yourEmailLabel');
    },
    [setEmail, setEmailLabel],
  );

  const openingHoursWeekdays = useMemo(() => {
    return {
      from: footer?.workingHours?.weekdays?.startTime || '',
      to: footer?.workingHours?.weekdays?.endTime || '',
    };
  }, [footer]);

  const openingHoursWeekend = useMemo(() => {
    return {
      from: footer?.workingHours?.weekend?.startTime || '',
      to: footer?.workingHours?.weekend?.endTime || '',
    };
  }, [footer?.workingHours?.weekend?.startTime, footer?.workingHours?.weekend?.endTime]);

  const socialLinks = useMemo(() => {
    return {
      telegram: footer?.socialNetworks?.telegram || '',
      vkontakte: footer?.socialNetworks?.vkontakte || '',
      youtube: footer?.socialNetworks?.youtube || '',
    };
  }, [footer?.socialNetworks]);

  const contacts = useMemo(() => {
    return {
      address: footer?.contacts?.address || '',
      email: footer?.contacts?.email || '',
      phone: footer?.contacts?.phone || '',
    };
  }, [footer?.contacts]);

  const parking = useMemo(() => {
    return footer?.parking || [];
  }, [footer?.parking]);

  useEffect(() => {
    getFooter();
    getDocuments();
  }, []);

  return (
    <footer className="footer" data-testid="footer">
      <div className="footer__grid">
        <div className="footer__grid-item footer__grid-item--1">
          <Link to="/">
            <LogoIcon className="footer__logo" data-testid="logo" />
          </Link>
        </div>
        <div className="footer__grid-item footer__grid-item--2">
          <NavBar
            data={navbarData}
            className="footer__navbar"
            listClassName="footer__navbar-list"
            itemClassName="footer__navbar-item"
          />
        </div>
        <div className="footer__grid-item footer__grid-item--3 footer__your-email">
          <SubscribeEmail
            email={email}
            emailLabel={emailLabel}
            isMobile={isMobile}
            onChangeEmail={onChangeEmail}
            submitEmail={submitEmail}
          />
        </div>
        {!isMobile && (
          <div className="footer__grid-item footer__grid-item--4 footer__social">
            <p className="footer__subtitle">{t('socialMedia')}</p>
            <SocialLink value={socialLinks} />
          </div>
        )}
        <div className="footer__grid-item footer__grid-item--5 footer__contacts">
          <p className="footer__subtitle">{t('contacts')}</p>
          <address>{contacts.address}</address>
          <address>
            <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
            <br />
            <a href={contacts.email}>{contacts.email}</a>
          </address>
          {isMobile && <SocialLink value={socialLinks} />}
        </div>
        <div className="footer__grid-item footer__grid-item--6 footer__opening-hours">
          <p className="footer__subtitle">{t('openingHours.title')}</p>
          <p className="footer__text">
            <span>
              {localizeDayOfWeek(1)} — {localizeDayOfWeek(6)}
            </span>
            <br />
            <strong>
              <Trans
                t={t}
                i18nKey="openingHours.hours"
                values={openingHoursWeekdays}
                // eslint-disable-next-line react/jsx-key
                components={[<time dateTime="10:00" />, <time dateTime="22:00" />]}
              />
            </strong>
          </p>
          <p className="footer__text">
            <span>{localizeDayOfWeek(0)}</span>
            <br />
            <strong>
              <Trans
                t={t}
                i18nKey="openingHours.hours"
                values={openingHoursWeekend}
                // eslint-disable-next-line react/jsx-key
                components={[<time dateTime="11:00" />, <time dateTime="22:00" />]}
              />
            </strong>
          </p>
        </div>
        <div className="footer__grid-item footer__grid-item--7 footer__parking-prices">
          <p className="footer__subtitle">{t('parking.title')}</p>

          {parking.map((parkingEl) => {
            return (
              <p className="footer__text" key={parkingEl.price}>
                <span>
                  <Trans
                    t={t}
                    i18nKey="parking.hours"
                    values={{
                      from: parkingEl.startTime,
                      to: parkingEl.endTime,
                    }}
                    // eslint-disable-next-line react/jsx-key
                    components={[<time dateTime={parkingEl.startTime} />, <time dateTime={parkingEl.endTime} />]}
                  />
                </span>
                <br />
                <strong>{`${parkingEl.price}₽/час`}</strong>
              </p>
            );
          })}
        </div>
      </div>
      <nav className="footer__info">
        <div className="footer__info-links">{infoLinksNodes}</div>
        <div>
          <a id="copyright-link" target="_blank" rel="noreferrer" href="https://metasharks.ru">
            <MetasharksLogo data-testid="metasharks-logo" />
          </a>
          <Tooltip
            anchorSelect="#copyright-link"
            className="footer__copyright-tooltip"
            content={t('copyrightTooltip') || ''}
          />
        </div>
      </nav>
    </footer>
  );
};
